import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { useGlobalContext } from "global/context";
import { apiAuth, apiJson } from "api";
import useError from "lib/errorResponse";
import NotFoundGif from "layout/NotFoundGif";
import SimpleBreadCrumb from "layout/SimpleBreadCrumb";
import { UserProfileCard } from "./components/UserProfileCard";
import { StudentBulkLogin2 } from "./components/StudentBulkLogin2";
import Avatar from "@mui/material/Avatar";

const ReportManagerAdmin = () => {
  const { ErrorResponder } = useError();
  const { token } = useGlobalContext();
  const { role } = useParams();
  const [useRoleData, setUseRoleData] = useState([]);
  const [selectUser, setSelectUser] = useState(null);
  const [showMaodal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { userData } = useGlobalContext();

  const handleNaviageProfile = () => {
    navigate(`/profile/user/${selectUser?.id}`);
  };

  const {
    details,
    students,
    fetchStudents,
    fetchDelegates,
    delegates,
    fetchStudentsStdCoordinators,
    StdCoordinate,
    searchTerm,
    setSearchTerm,
  } = useOutletContext();
  const handleDelete = async (id) => {
    const confirmation = window.confirm("Are you sure you want to delete");
    if (confirmation) {
      if (token) {
        try {
          const res = await apiAuth.delete(
            "/institute/student?studentId=" + id,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          if (res.status == 200) {
            toast.dismiss();
            toast.success(res.data.message);
            fetchStudents();
          }
        } catch (error) {
          ErrorResponder(error);
          toast.dismiss();
          toast.error(error?.response?.data?.message);
        }
      }
    }
  };
  const [filterDelegates, setFilterDelegates] = useState([]);
  const mapDelegates = () => {
    let localfilterDelegates = [];
    delegates.map((value) => {
      localfilterDelegates.push(value.studentId);
    });
    setFilterDelegates(localfilterDelegates);
  };
  useEffect(() => {
    mapDelegates();
  }, [students]);

  useEffect(() => {
    const filteruserRole = students?.filter((ele, i) => {
      return ele?.role === role;
    });
    setUseRoleData(filteruserRole);
  }, [students, role]);

  const handleshowModal = (id) => {
    const user = students.find((student) => student?.id === id);
    setSelectUser(user);
    setShowModal(true);
  };

  const handleCloseShowModal = () => {
    setShowModal(false);
  };

  const [getExcelData, setExcelData] = useState([]);
  const getExcelSheetData = () => {
    apiJson
      .get(`api/v2/institute/getMultipleTeacherStudent/${userData?.id}`)
      .then((res) => {
        setExcelData(res?.data?.getAllTeachStud);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    getExcelSheetData();
  }, []);

  return (
    <div>
      <div className="col-10 mx-auto mt-4">
        <div className="row">
          <div className="col-12  col-md-12 col-lg-4 ">
            <div className="">
              <span className="my-teacher-student-content">{role === "student" ? "Students Report Manager" : "Report Manager"}</span>
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-8">
            <StudentBulkLogin2
              getExcelSheetData={getExcelSheetData}
              details={details}
              data={students}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              reload={fetchStudents}
              role={role}
            />
          </div>
        </div>
      </div>
      <div className="container py-2">

        {students?.filter((i) => {
          return i.role === role;
        }).length !== 0 && (
            <div className="col-10 mx-auto">
              <p className="fs-5">
                Total {role} is{" "}
                {
                  students?.filter((i) => {
                    return i.role === role;
                  }).length
                }
              </p>
            </div>
          )}


        <div className="col-10 mx-auto">

          <div>
            {
              <div>
                <div className="row">
                  {useRoleData.length > 0 ? (
                    useRoleData?.map((student, i) => {
                      return (
                        <>
                          {student?.role === role && (
                            <div className="d-flex justify-content-center col-12 col-md-6 col-lg-3">
                              <UserProfileCard
                                // key={i}
                                // details={details}
                                // stdCoordinator={StdCoordinate}
                                // student={student}
                                // role={role}
                                // fetchStudents={fetchStudents}
                                // handleDelete={handleDelete}
                                // handleshowModal={handleshowModal}
                                // fetchDelegates={fetchDelegates}
                                // delegates={filterDelegates}
                                // fetchStudentsStdCoordinators={
                                //   fetchStudentsStdCoordinators
                                // }
                                user={student} handleShowModal={() => { }}
                              />
                            </div>
                          )}
                        </>
                      );
                    })
                  ) : (
                    <>
                      <NotFoundGif text={`No ${role} Has Registered Yet!`} />
                    </>
                  )}
                </div>
              </div>
            }
          </div>

          <hr />

          <div className="row">
            {getExcelData?.map((ele) => {
              return (
                <>
                  {ele?.role === role && (
                    <div className="d-flex justify-content-center col-12 col-md-4 col-lg-3">
                      <div className="px-3 py-4">
                        <div
                          className="card margin-right-25px  px-6 py-2 border-0 shadow rounded-5"
                          style={{ width: "173px", height: "243px" }}
                        >
                          <div className="card-body mx-auto d-flex px-0 py-0">
                            <div className="h-100 w-100">
                              <div className="margin-top-11px d-flex justify-content-center">
                                <div
                                  style={{
                                    objectFit: "cover",
                                    height: "98px",
                                    width: "98px",
                                  }}
                                >
                                  <Avatar
                                    className="h-100 w-100"
                                    style={{
                                      borderRadius: "64.5px",
                                    }}
                                    alt={ele.first_name}
                                  // src={ele?.profile}
                                  />
                                </div>
                              </div>
                              <div
                                className="degination d-flex justify-content-center align-items-center"
                                style={{
                                  background: "#FFE7AA",
                                  position: "relative",
                                  bottom: "8px",
                                }}
                              >
                                <span
                                  className="font-family-Lexend font-size-11px d-block"
                                  style={{ color: "#7B4300" }}
                                >
                                  {ele?.role}
                                  {/* {ele?.class || "10th" } */}
                                </span>
                              </div>
                              <div className="height-48px-width-99px justify-content-center text-center mx-auto d-flex align-items-center mt-1">
                                <p
                                  className="font-family-Lexend font-weight-600"
                                  style={{ fontSize: "16px" }}
                                >
                                  {ele?.first_name} {ele?.last_name}
                                </p>
                              </div>

                              {/* <div onClick={() => handleshowModal(ele?.id)} className="mt-1 mx-auto rounded text-center" style={{ background: "#c4afff", width: "100px", height: "27px", cursor: "pointer" }}>
                          <div><span style={{ color: "#5a0c99", fontSize: "12px", fontWeight: "600", fontFamily: "Lexend" }}>View Profile</span></div>
                        </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>

      <Modal show={showMaodal} onHide={handleCloseShowModal}>
        <Modal.Header closeButton>
          <Modal.Title>View Details</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="ps-3">
            <div className="row mt-1">
              <div className="d-flex col-12 col-lg-3">
                <div>
                  <span className="fw-700">Name</span>
                </div>
              </div>

              <div
                className="col-12 col-lg-6 fw-400"
                style={{ fontFamily: "Poppins" }}
              >
                <div className="fw-400">
                  <span className="text-capitalize">
                    {selectUser?.first_name + " " + selectUser?.last_name}
                  </span>
                </div>
              </div>
              <div></div>
            </div>

            <div className="row mt-1">
              <div className="d-flex col-12 col-lg-3">
                <div className="fw-700">
                  <span>Email</span>
                </div>
              </div>

              <div className="col-12 col-lg-6">
                <div className="fw-400">
                  <span>{selectUser?.email}</span>
                </div>
              </div>
              <div></div>
            </div>

            <div className="row mt-1">
              <div className="d-flex col-12 col-lg-3">
                <div className="fw-700">
                  <span>D.O.B</span>
                </div>
              </div>

              <div className="col-12 col-lg-6">
                <div className="fw-400">
                  <span>{moment(selectUser?.dob).format("DD-MM-YYYY")}</span>
                </div>
              </div>
              <div></div>
            </div>

            {selectUser?.address && (
              <div className="row  mt-1">
                <div className="d-flex col-12 col-lg-3">
                  <div className="fw-700">
                    <span>Address</span>
                  </div>
                </div>

                <div className="col-12 col-lg-6">
                  <div className="fw-400">
                    <span>{selectUser?.address}</span>
                  </div>
                </div>
                <div></div>
              </div>
            )}

            <div className="row  mt-1">
              <div className="d-flex col-12 col-lg-3">
                <div className="fw-700">
                  <span>Contact</span>
                </div>
              </div>

              <div className="col-12 col-lg-6">
                <div className="fw-400">
                  <span>+91 {selectUser?.contact}</span>
                </div>
              </div>
              <div></div>
            </div>

            {selectUser?.father_name && (
              <div className="row  mt-1">
                <div className="d-flex col-12 col-lg-3">
                  <div className="fw-700">
                    <span>Father Name</span>
                  </div>
                </div>

                <div className="col-12 col-lg-6">
                  <span>{selectUser?.father_name}</span>
                </div>
                <div></div>
              </div>
            )}

            {/* class */}
            {selectUser?.class && (
              <div className="row  mt-1">
                <div className="d-flex col-12 col-lg-3">
                  <div className="fw-700">
                    <span>Class</span>
                  </div>
                </div>

                <div className="col-12 col-lg-6">
                  <div className="fw-400">
                    <span>{selectUser?.class}</span>
                  </div>
                </div>
                <div></div>
              </div>
            )}

            {/* Role */}
            {selectUser?.role && (
              <div className="row mt-1">
                <div className="d-flex col-12 col-lg-3">
                  <di className="fw-700">
                    <span>Role</span>
                  </di>
                </div>

                <div className="col-12 col-lg-6">
                  <div className="fw-400">
                    <span className="text-capitalize">{selectUser?.role}</span>
                  </div>
                </div>
                <div></div>
              </div>
            )}
          </div>
          <div className="text-end">
            <button
              className="badge  text-bg-primary p-2"
              onClick={handleNaviageProfile}
            >
              View Profile
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ReportManagerAdmin;
const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "143px",
    height: "40px",
    padding: "3px",
    border: "none",
    background: "#F9ECFF",
    color: "black",
    // boxShadow:'none'
  }),
};
