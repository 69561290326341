import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, TextField, Box } from '@mui/material';
import moment from 'moment';

const validateField = (key, value, role) => {
    const regexes = {
        'contact': /^\d{10}$/,
        'email': /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        'first_name': /^[a-zA-Z]+$/,
        'last_name': /^[a-zA-Z]+$/,
        'father_name': /^[a-zA-Z]+(?:\s[a-zA-Z]+)?$/,
    };

    if (value === undefined || value === null || String(value).trim() === '') {
        return false;
    }

    switch (key) {
        case 'dob':
            const isValidFormat = moment(value, ['D-M-YYYY', 'DD-MM-YYYY'], true).isValid();
            if (!isValidFormat) return false;
            const birthDate = moment(value, ['D-M-YYYY', 'DD-MM-YYYY']);
            if (birthDate.isAfter(moment())) return false;
            const age = moment().diff(birthDate, 'years');
            if (role === 'student' && age <= 10) return false;
            if (role === 'teacher' && age <= 20) return false;
            return true;
        case 'contact':
            return regexes.contact.test(value);
        case 'email':
            return regexes.email.test(value);
        case 'first_name':
        case 'last_name':
            return regexes.first_name.test(value);
        case 'father_name':
            return regexes.father_name.test(value);
        default:
            return true;
    }
};
const SpreadsheetView = ({ incorrectData, onUpdateUser }) => {
    const [editedData, setEditedData] = useState(incorrectData);
    const [validationState, setValidationState] = useState(
        incorrectData.map(row => Object.keys(row).reduce((acc, key) => ({
            ...acc,
            [key]: validateField(key, row[key], row.role)
        }), {}))
    );
    const [selectedCell, setSelectedCell] = useState(null);

    const hiddenFields = ['id', 'instituteId', 'role'];

    const handleChange = (index, key, value) => {
        const updatedData = [...editedData];
        updatedData[index][key] = value;
        setEditedData(updatedData);
    };

    const handleBlur = async (index) => {
        const currentRow = editedData[index];
        const isRowValid = Object.keys(currentRow).every(key => validateField(key, currentRow[key], currentRow.role));

        if (isRowValid) {
            await onUpdateUser(currentRow.id, currentRow);

            const updatedData = editedData.filter((_, i) => i !== index);
            setEditedData(updatedData);

            const updatedValidationState = validationState.filter((_, i) => i !== index);
            setValidationState(updatedValidationState);
        } else {
            setValidationState(prevState => {
                const updatedValidation = [...prevState];
                updatedValidation[index] = Object.keys(currentRow).reduce((acc, key) => ({
                    ...acc,
                    [key]: validateField(key, currentRow[key], currentRow.role)
                }), {});
                return updatedValidation;
            });
        }
    };
    const handleKeyDown = async (index, key, event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            await handleBlur(index);
            const nextCellIndex = index + 1;
            if (nextCellIndex < editedData.length) {
                setSelectedCell({ index: nextCellIndex, key: headers[0] });
            }
        }
    };

    const handleFocus = (index, key) => {
        setSelectedCell({ index, key });
    };

    const headers = Object.keys(incorrectData[0] || {}).filter(header => !hiddenFields.includes(header));

    return (
        <Box sx={{ overflowX: 'auto', maxWidth: '100%' }}>
            <Table
                sx={{
                    backgroundColor: '#ffffff',
                    borderCollapse: 'collapse',
                    width: '100%',
                    tableLayout: 'auto',
                }}
            >
                <TableHead>
                    <TableRow>
                        {headers.map((header) => (
                            <TableCell
                                key={header}
                                sx={{
                                    color: '#000000',
                                    backgroundColor: '#f1f1f1',
                                    borderColor: '#d9d9d9',
                                    textAlign: 'center',
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {header.replace(/([A-Z])/g, ' $1').toUpperCase()}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {editedData.map((user, index) => (
                        <TableRow key={user.id}>
                            {headers.map((header) => (
                                <TableCell
                                    key={header}
                                    sx={{
                                        color: '#000000',
                                        borderColor: '#d9d9d9',
                                        padding: '0',
                                        border: '1px solid #d9d9d9',
                                        '&:focus-within': {
                                            outline: '2px solid #64b5f6',
                                            outlineOffset: '-2px',
                                        },
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        value={user[header] || ''}
                                        onChange={(e) => handleChange(index, header, e.target.value)}
                                        onBlur={() => handleBlur(index)}
                                        onFocus={() => handleFocus(index, header)}
                                        onKeyDown={(e) => handleKeyDown(index, header, e)}
                                        fullWidth
                                        inputProps={{
                                            style: {
                                                padding: '8px',
                                                height: '40px',
                                                boxSizing: 'border-box',
                                            },
                                        }}
                                        sx={{
                                            backgroundColor: selectedCell?.index === index && selectedCell?.key === header ? '#e3f2fd' : '#ffffff',
                                            color: '#000000',
                                            borderColor: '#d9d9d9',
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#d9d9d9',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#64b5f6',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#64b5f6',
                                            },
                                        }}
                                        size="small"
                                        disabled={validationState[index][header]}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box >
    );
};

export default SpreadsheetView;
