import React, { useState } from 'react';
import { Button, Grid, Box } from '@mui/material';
import Spreadsheet from 'react-spreadsheet';
import moment from 'moment';
import { useParams } from 'react-router-dom';

function BulkDataFormat({ onClose, data, onSave }) {
  const { role } = useParams();
  const [spreadsheetData, setSpreadsheetData] = useState(() => {
    if (data.length > 0) {
      const headers = [
        'First_Name', 'Last_Name', 'Father_Name', 'Contact', 'Email',
        'Date_Of_Birth', 'Class', 'Stream', 'Gender'
      ];
      const formattedData = [
        headers.map(header => ({ value: header, isHeader: true })),
        ...data.map(row =>
          headers.map(header => ({ value: row[header] || '', isHeader: false }))
        )
      ];
      return formattedData;
    }
    return [];
  });

  const validateField = (key, value) => {
    const regexes = {
      'Contact': /^\d{10}$/,
      'Email': /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      'First_Name': /^[a-zA-Z]+$/,
      'Last_Name': /^[a-zA-Z]+$/,
      'Father_Name': /^[a-zA-Z]+(?:\s[a-zA-Z]+)?$/,
    };

    if (value === undefined || value === null || String(value).trim() === '') {
      return false;
    }

    switch (key) {
      case 'Date_Of_Birth':
        const isValidFormat = moment(value, ['D-M-YYYY', 'DD-MM-YYYY'], true).isValid();
        if (!isValidFormat) return false;
        const birthDate = moment(value, ['D-M-YYYY', 'DD-MM-YYYY']);
        if (birthDate.isAfter(moment())) return false;
        const age = moment().diff(birthDate, 'years');
        if (role === 'student' && age <= 10) return false;
        if (role === 'teacher' && age <= 20) return false;
        return true;
      case 'Contact':
        return regexes.Contact.test(value);
      case 'Email':
        return regexes.Email.test(value);
      case 'First_Name':
      case 'Last_Name':
        return regexes.First_Name.test(value);
      case 'Father_Name':
        return regexes.Father_Name.test(value);
      default:
        return true;
    }
  };


  const handleSave = () => {
    const headers = spreadsheetData[0].map(header => header.value);
    const updatedData = spreadsheetData.slice(1).map(row => {
      let rowData = {};
      row.forEach((cell, index) => {
        rowData[headers[index]] = cell.value !== undefined && cell.value !== null ? cell.value : '';
      });
      return rowData;
    });

    const correctDataList = [];
    const incorrectDataList = [];

    updatedData.forEach(item => {
      let isValid = true;

      Object.keys(item).forEach(key => {
        if (!validateField(key, item[key])) {
          isValid = false;
        }
      });

      if (isValid) {
        correctDataList.push(item);
      } else {
        incorrectDataList.push(item);
      }
    });

    console.log('Correct Data List:', correctDataList);
    console.log('Incorrect Data List:', incorrectDataList);
    console.log('Updated Data:', updatedData);

    onSave({ correctDataList, incorrectDataList });
    onClose();
  };

  const handleClose = () => {
    onSave(data);
    onClose();
  };

  return (
    <Box
      sx={{
        padding: '16px',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        maxWidth: '800px',
        margin: '0 auto'
      }}
    >
      <Grid container spacing={2} sx={{ paddingBottom: '16px' }}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '400px',
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
            }}
          >
            <Spreadsheet
              data={spreadsheetData}
              onChange={data => {
                setSpreadsheetData(data);
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '8px',
        }}
      >
        <Button
          variant="outlined"
          sx={{ color: 'grey', borderColor: 'grey' }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'green', color: 'white' }}
          onClick={handleSave}
        >
          Save Changes
        </Button>
      </Box>
    </Box>
  );
}

export default BulkDataFormat;
