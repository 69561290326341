import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
} from "@mui/material";
import * as XLSX from "xlsx";
import BulkDataFormat from "./BulkDataFormat";
import YuvaLoader from "pages/Forum/components/Loader/YuvaLoader";
import { Popup } from "layout/Popup";
import { Download } from "@mui/icons-material";
import { apiJsonAuth } from "api";
import { useGlobalContext } from "global/context";

const BulkUploadModal = ({ role, open, onClose }) => {
  const [fileData, setFileData] = useState([]);
  const [showEditor, setShowEditor] = useState(false);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useGlobalContext();
  const handleFileUpload = async (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const fileType = file.name.split(".").pop().toLowerCase();

      if (["csv", "xlsx", "xls"].includes(fileType)) {
        const reader = new FileReader();

        reader.onload = (event) => {
          try {
              const data = new Uint8Array(event.target.result);
              const workbook = XLSX.read(data, { type: 'array' });
              const firstSheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[firstSheetName];
              let jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

              if (jsonData.length === 0 || jsonData.length === 1) {
                  setShowEditor(false);
                  Popup("error", "Data Not Found or Only One Row Available!");
                  setFileData([]);
                  return;
              }

              if (jsonData.length > 5000) {
                  setShowEditor(false);
                  Popup("error", "Data exceeds the limit of 5000 records!");
                  setFileData([]);
                  return;
              }

              const requiredHeaders = [
                  'First_Name', 'Last_Name', 'Father_Name', 'Contact',
                  'Email', 'Date_Of_Birth', 'Gender'
              ];

              if (role === 'student') {
                  requiredHeaders.push('Stream');
                  requiredHeaders.push('Class');
              }

              const headers = jsonData[0].map(header =>
                  header.trim().toLowerCase().replace(/\s+/g, '_')
              );

              console.log("Extracted Headers:", headers);

              const headerMap = {
                  'date_of_birth': 'Date_Of_Birth',
                  'first_name': 'First_Name',
                  'last_name': 'Last_Name',
                  'father_name': 'Father_Name',
                  'contact': 'Contact',
                  'email': 'Email',
                  'class': 'Class',
                  'stream': 'Stream',
                  'gender': 'Gender'
              };

              const normalizedHeaders = headers.map(header => headerMap[header] || header);
              console.log("Normalized Headers:", normalizedHeaders);
              const missingHeaders = requiredHeaders.filter(header => !normalizedHeaders.includes(header));
              if (missingHeaders.length > 0) {
                  setShowEditor(false);
                  Popup("error", `File Format Incorrect. Missing headers: ${missingHeaders.join(', ')}`);
                  setFileData([]);
                  return;
              }

              const users = jsonData.slice(1).map(row => {
                  let user = {};
                  normalizedHeaders.forEach((header, index) => {
                      if (header === 'Date_Of_Birth') {
                          const cellValue = row[index];
                          let formattedDate = '';

                          if (cellValue instanceof Date) {
                              formattedDate = XLSX.SSF.format('dd-mm-yyyy', cellValue);
                          } else if (typeof cellValue === 'number') {
                              const date = XLSX.SSF.parse_date_code(cellValue);
                              formattedDate = XLSX.SSF.format('dd-mm-yyyy', new Date(date.y, date.m - 1, date.d));
                          } else if (typeof cellValue === 'string') {
                              formattedDate = cellValue;
                          }

                          user['Date_Of_Birth'] = formattedDate;
                      } else {
                          user[header] = row[index] || '';
                      }
                  });
                  return user;
              });

              console.log("Data got from File: ", users);
              setFileData(users);
              setShowEditor(true);

          } catch (error) {
              setShowEditor(false);
              Popup("error", "Error reading file.");
              setFileData([]);
          }
      };

        reader.readAsArrayBuffer(file);
      } else {
        setShowEditor(false);
        Popup("error", "Unsupported file format!");
        setFileData([]);
      }
    }
  };

  const handleSaveChanges = async (updatedData) => {
    console.log("DataSubmitted: ", updatedData);

    const uploadData = {
      correctData: updatedData.correctDataList || [],
      incorrectData: updatedData.incorrectDataList || [],
      role: role,
    };

    console.log("Upload Data: ", uploadData);

    try {
      const response = await apiJsonAuth.post(
        "api/v2/institute/studentBulkLogin",
        uploadData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { dataSaved, dataFailed, message } = response.data;

      setShowEditor(false);
      Popup("success", message);
      console.log("Data Saved Successfully: ", dataSaved);
      console.log("Data Failed: ", dataFailed);
      onClose();
    } catch (error) {
      setShowEditor(false);
      console.error(error);
      Popup("error", "An error occurred while saving data.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Bulk Upload</DialogTitle>
      <DialogContent dividers>
        {!showEditor ? (
          <div>
            <div className="text-center item-center text-danger">
              <span className="font-weight-bold">NOTE</span>
              <br />
              <span className="lh-1 fw-semibold">
                1. Only the Provided Format is allowed for file upload <br />
                2. Data limit is 5000 Users per Upload.{" "}
              </span>{" "}
              <br />
              <Tooltip title="Use Provided file format to upload the student details">
                {role === "teacher" ? (
                  <Button
                    style={{ color: "white" }}
                    className="mt-2 rounded"
                    variant="contained"
                    color="success"
                    href="https://s3.ap-south-1.amazonaws.com/yuvamanthan.org/bulkRegister/Teacher_format.xlsx"
                    target="_blank"
                    startIcon={<Download />}
                  >
                    Download&nbsp;Template
                  </Button>
                ) : (
                  <Button
                    style={{ color: "white" }}
                    className="mt-2 rounded"
                    variant="contained"
                    color="success"
                    href="https://s3.ap-south-1.amazonaws.com/yuvamanthan.org/bulkRegister/Student_format.xlsx"
                    target="_blank"
                    startIcon={<Download />}
                  >
                    Download&nbsp;Template
                  </Button>
                )}
              </Tooltip>
            </div>
            <div
              className="rounded-4 pb-3 mt-4 mx-auto"
              style={{
                position: "relative",
                overflow: "hidden",
                maxWidth: "500px",
                border: "2px dashed grey",
              }}
            >
              <input
                className="fade"
                style={{
                  minHeight: 240,
                  width: "100%",
                  zIndex: 200,
                  position: "relative",
                }}
                type="file"
                onClick={(e) => {
                  setFileData([]);
                  setFile(null);
                  e.target.value = null;
                }}
                onChange={(e) => {
                  setIsLoading(true);
                  setFile(e.target.files[0]);
                  handleFileUpload(e);
                  setIsLoading(false);
                }}
                id="fileData"
              />

              {isLoading && <YuvaLoader />}
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 100,
                }}
                className="w-100 h-100"
              >
                <div className="h-100 w-100 bg-light">
                  <div className="py-4 text-center">
                    <h4>Drag and drop or Click To Add File.</h4>
                    <img
                      src="/images/upload-cloud-folder.png"
                      alt=""
                      width={200}
                      style={{ objectFit: "contain" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <BulkDataFormat
            open={showEditor}
            onClose={() => setShowEditor(false)}
            data={fileData}
            onSave={handleSaveChanges}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkUploadModal;
