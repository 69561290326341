import {
  // Button,
  Dialog,
  DialogContent,
  DialogTitle,
  // Tooltip,
} from "@mui/material";
import { apiJson, apiJsonAuth } from "api";
import { Popup } from "layout/Popup";
import React, { useEffect, useRef, useState } from "react";
import * as xlsx from "xlsx";
import { useGlobalContext } from "global/context";
import useError from "lib/errorResponse";
import StudentSingleRegister from "./StudentSingleRegister";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import BulkDataFormat from "./BulkDataFormat";
import LoaderSplash from "layout/LoaderSplash";
import moment from 'moment';
import {
  Clear,
  FilePresentTwoTone,
  FileUploadTwoTone,
  LocationCity,
  Search,
  Download,
} from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import YuvaLoader from "pages/Forum/components/Loader/YuvaLoader";
import { renderToString } from "react-dom/server";

export const StudentBulkLogin2 = ({
  details,
  data,
  searchTerm,
  setSearchTerm,
  reload,
  role,
  getExcelSheetData,
}) => {
  const { userData } = useGlobalContext();
  console.log(role, "role value")
  // const [fileName, setFileName] = React.useState();
  // const [file, setFile] = React.useState();
  // const [fileData, setFileData] = React.useState();
  // const [dataSaved, setDataSaved] = React.useState();
  // const [dataFailed, setDataFailed] = React.useState();
  // const { token } = useGlobalContext();
  // const closeBtn = useRef();
  // const { ErrorResponder } = useError();
  // let [update, setUpdate] = useState(0);
  // const setStudentData = async () => {
  //   if (file && fileData && token) {
  //     try {
  //       const formData = new FormData();
  //       formData.append('file', file, file.originalname);
  //       formData.append('role', role);
  //       const response = await apiJsonAuth.post('institute/bulklogin', formData, {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //           Authorization: token,
  //         },
  //       });

  //       Popup('success', response?.data?.message);
  //       if (response.data.dataSaved) {
  //         setDataSaved(response?.data?.dataSaved);
  //         setUpdate(update + 1);
  //       }
  //       if (response.data.dataFailed) {
  //         setDataFailed(response?.data?.dataFailed);
  //       }
  //     } catch (error) {
  //       ErrorResponder(error);
  //     }
  //   } else {
  //     Popup('warning', 'File Not Found!');
  //   }
  // };

  // const convertFile = (e) => {
  //   if (e.target.files[0]) {
  //     const file = e.target.files[0];
  //     let fileType = file?.name.split('.').slice(-1);
  //     if (file && (fileType[0] === 'csv' || fileType[0] === 'xlsx' || fileType[0] === 'xls')) {
  //       setFileName(file.name);
  //       const reader = new FileReader();
  //       reader.readAsArrayBuffer(file);
  //       reader.onload = (e) => {
  //         const data = e.target.result;
  //         const workbook = xlsx.read(data, { type: 'buffer', skiprows: 0 });
  //         const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  //         const json = xlsx.utils.sheet_to_json(worksheet);
  //         if (json.length === 0) {
  //           Popup('error', 'Data Not Found!');
  //           setFile();
  //         } else if (json.length > 1000) {
  //           Popup('error', 'Exceed Data Limit !!! \n Data Limit : 1000');
  //           setFile();
  //         } else if (!json[0]?.First_Name && !json[0]?.Contact && !json[0]?.Email) {
  //           Popup('error', 'File Format Incorrect');
  //           setFile();
  //         } else {
  //           setFileData(json);
  //         }
  //       };
  //     } else {
  //       Popup('error', 'File Format is not Suported!!');
  //     }
  //   }
  // };
  // function handleClose() {
  //   setDataSaved();
  //   setDataFailed();
  //   setFileData();
  //   setFileName();
  //   setFile();
  // }

  // function downloadSavedData() {
  //   let savelist = [];
  //   dataSaved.map((data) => {
  //     savelist.push({
  //       ID: data?.id,
  //       First_Name: data?.first_name,
  //       Last_Name: data?.last_name,
  //       Email: data?.email,
  //       Password: data?.password,
  //     });
  //   });
  //   downloadExcel(savelist);
  // }
  // const downloadExcel = (data) => {
  //   if (data) {
  //     const worksheet = xlsx.utils.json_to_sheet(data);
  //     const workbook = xlsx.utils.book_new();
  //     xlsx.utils.book_append_sheet(workbook, worksheet, 'sheet1');
  //     xlsx.writeFile(workbook, 'SampleData.xlsx');
  //   }
  // };
  // useEffect(() => {
  //   if (token) {
  //     if (searchTerm === '') {
  //       reload();
  //     }
  //   }
  // }, [searchTerm]);

  // Participants
  const [open, setOpen] = React.useState(false);
  // const ApplyForParticipation = async (id) => {
  //   try {
  //     // toast.loading("Loading...")
  //     const result = await apiJsonAuth.post('/student/apply/delegate', {
  //       studentId: id,
  //       user: 'INSTITUTE',
  //     });
  //     switch (result?.data?.status) {
  //       case 'success':
  //         // toast.dismiss();
  //         // toast.success("Student is Added to Delegates.");
  //         // console.log("Student is Added to Delegates.");
  //         break;
  //       case 'warning':
  //         // toast.dismiss();
  //         // toast.warning(result?.data?.message);
  //         // console.log(result?.data?.message);
  //         break;
  //       case 'conflict':
  //         // toast.dismiss();
  //         // toast.warning(result?.data?.message);
  //         // console.log(result?.data?.message);
  //         break;
  //       case 'error':
  //         // toast.dismiss();
  //         // toast.error(result?.data?.message);
  //         // console.log(result?.data?.message);
  //         break;
  //       default:
  //         // toast.dismiss();
  //         // toast.info("OOps Something Went Wrong Please try again later.");
  //         // console.log("OOps Something Went Wrong Please try again later.")
  //         break;
  //     }
  //     reload();
  //   } catch (error) {
  //     console.log({ error });
  //   }
  // };
  // const BulkStudentAssigner = (array) => {
  //   array.forEach((candidate, index) => {
  //     if (index < autoAssignLimit) {
  //       ApplyForParticipation(candidate?.id);
  //       if (index === array.length - 1) {
  //         setOpen(false);
  //         toast.success('Auto Assigned the Designations.');
  //       }
  //     } else {
  //       setOpen(false);
  //       toast.success('Auto Assigned the Designations.');
  //     }
  //   });
  // };
  // useEffect(() => {
  //   reload();
  // }, [update]);
  // console.log(details.id);

  // Related To BUlk Auto Assigner
  const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // const handleAssignerOpen = () => {
  //   setOpen(true);
  // };
  // const handleAssignerClose = () => {
  //   setOpen(false);
  // };
  const [autoAssignLimit, setAutoAssignLimit] = useState(20);
  // let [eligibleCandidates, setEligibleCandidates] = useState([]);
  // useEffect(() => {
  //   data?.forEach((student, index) => {
  //     if (student?.certified) {
  //       const arrayCheck = eligibleCandidates.map((candidate, index) => {
  //         return candidate.id;
  //       });
  //       if (!arrayCheck?.includes(student?.id)) {
  //         setEligibleCandidates([...eligibleCandidates, student]);
  //       }
  //     }
  //   });
  //   // console.log("ELIGIBLE", eligibleCandidates);
  // }, [data, reload]);
  // const removeFromEligible = (id) => {
  //   let n = eligibleCandidates.findIndex((std) => std.id === id);
  //   let final = eligibleCandidates.splice(n, 1);
  //   console.log({ final });
  //   setEligibleCandidates(final)
  // }
  const [openEditor, setOpenEditor] = useState(false);
  const [fileName, setFileName] = React.useState();
  const [file, setFile] = React.useState();
  const [isLoading, setIsLoading] = useState(false);
  const [fileData, setFileData] = React.useState();
  const [dataSaved, setDataSaved] = React.useState();
  const [dataFailed, setDataFailed] = React.useState();
  const { token } = useGlobalContext();
  const closeBtn = useRef();

  const { ErrorResponder } = useError();
  let [update, setUpdate] = useState(0);
  const setStudentData = async () => {
    if (file && fileData && token) {
      const correctDataList = [];
      const incorrectDataList = [];

      const nameRegex = /^[a-zA-Z ]*$/;
      const emailRegex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
      const contactRegex = /^\d{10}$/;
      const dobRegex = /^(0[1-9]|1[0-2])[-/](0[1-9]|[12][0-9]|3[01])[-/]\d{4}$/;

      fileData.forEach(item => {
        let isValid = true;

        if (!item?.Contact || !item?.Email || !item?.Date_Of_Birth || !item?.Gender) isValid = false;
        if (!contactRegex.test(item?.Contact)) isValid = false;
        if (!emailRegex.test(item?.Email)) isValid = false;
        if (!dobRegex.test(item?.Date_Of_Birth)) isValid = false;
        if (item?.Father_Name && !nameRegex.test(item?.Father_Name)) isValid = false;
        if (item?.First_name && !nameRegex.test(item?.First_name)) isValid = false;
        if (item?.Last_name && !nameRegex.test(item?.Last_name)) isValid = false;

        if (isValid) {
          correctDataList.push(item);
        } else {
          incorrectDataList.push(item);
        }
      });

      try {
        const formData = {
          correctData: correctDataList,
          incorrectData: incorrectDataList,
          role: role,
        }

        console.log("formData : 259", formData);
        const response = await apiJsonAuth.post(
          "api/v2/institute/manageTeacherStudent ",
          formData,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        Popup("success", response?.data?.message);
        if (response.data.dataSaved) {
          setDataSaved(response?.data?.dataSaved);
          setUpdate(update + 1);
          getExcelSheetData();
        }
        if (response.data.dataFailed) {
          setDataFailed(response?.data?.dataFailed);
        }
      } catch (error) {
        ErrorResponder(error);
      }
    } else {
      Popup("warning", "File Not Found!");
    }
  };

  const convertFile = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const fileType = file.name.split('.').pop();

      if (['csv', 'xlsx', 'xls'].includes(fileType)) {
        setFileName(file.name);
        const reader = new FileReader();

        reader.onload = (event) => {
          try {
            const data = event.target.result;
            const workbook = xlsx.read(data, { type: 'array' });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const json = xlsx.utils.sheet_to_json(worksheet);

            console.log("Raw JSON Data:", json);

            if (json.length === 0) {
              Popup("error", "Data Not Found!");
              setFileData([]);
            } else if (json.length > 5000) {
              Popup("error", "Data exceeds the limit of 5000 records!");
              setFileData([]);
            } else if (!json[0]?.First_name || !json[0]?.Contact || !json[0]?.Email) {
              Popup("error", "File Format Incorrect");
              setFileData([]);
            } else {
              const normalizedData = json.map(item => {
                if (item.Date_Of_Birth) {
                  item.Date_Of_Birth = moment(item.Date_Of_Birth).format('DD-MM-YYYY');
                }
                return item;
              });

              console.log("Normalized Data:", normalizedData);
              setFileData(normalizedData);
            }
          } catch (error) {
            Popup("error", "Error reading file.");
            setFileData([]);
          }
        };

        reader.readAsArrayBuffer(file);
      } else {
        Popup("error", "Unsupported file format!");
      }
    }
  };

  function handleClose() {
    setDataSaved();
    setDataFailed();
    setFileData();
    setFileName();
    setFile();
  }

  function downloadSavedData() {
    let savelist = [];
    dataSaved.map((data) => {
      savelist.push({
        ID: data?.id,
        First_name: data?.first_name,
        Last_name: data?.last_name,
        Email: data?.email,
        Contact: data?.contact,
        // Password: data?.password,
      });
    });
    downloadExcel(savelist);
  }
  const downloadExcel = (data) => {
    if (data) {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "sheet1");
      xlsx.writeFile(workbook, "SampleData.xlsx");
    }
  };
  useEffect(() => {
    if (token) {
      if (searchTerm === "") {
        reload();
      }
    }
  }, [searchTerm]);

  // Participants
  useEffect(() => {
    reload();
  }, [update]);
  // console.log(searchTerm);

  // Related To BUlk Auto Assigner
  let [eligibleCandidates, setEligibleCandidates] = useState([]);
  useEffect(() => {
    data?.forEach((student, index) => {
      if (student?.certified) {
        const arrayCheck = eligibleCandidates.map((candidate, index) => {
          return candidate.id;
        });
        if (!arrayCheck?.includes(student?.id)) {
          setEligibleCandidates([...eligibleCandidates, student]);
        }
      }
    });
    // console.log("ELIGIBLE", eligibleCandidates);
  }, [data, reload]);

  // console.log('fileData', fileData)
  // console.log('dataFailed', dataFailed)

  return (
    <>
      {/* <div className="input-group border rounded-0 border-0">
              <input
                className="form-control box-shadow-0 border-0 rounded"
                type="text"
                style={{background: "rgb(239, 239, 239)"}}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    reload();
                  }
                }}
                value={searchTerm}
                placeholder={'Search ' + role + ' Name Here....'}
                aria-label={'Search ' + role + ' Name Here....'}
                // aria-describedby="button-addon2"
              />
              {searchTerm.length ? (
                <button
                  className="btn btn-sm btn-danger border-0 rounded"
                  type="button"
                style={{background: "#f40157"}}
  
                  id="button-addon3"
                  onClick={() => {
                    setSearchTerm('');
                    reload();
                  }}>
                  <i className="bi bi-x-circle"></i>
                  <small className="d-lg-inline-block">&nbsp;Clear</small>
                </button>
              ) : (
                ''
              )}
              <button
                className="btn btn-success fw-thin text-white border-0 rounded"
                type="button"
                id="button-addon2"
                style={{background: "#9b5ffd"}}
                onClick={() => {
                  reload();
                }}>
                <i className="bi bi-search"></i>
                <small className="d-lg-inline-block">&nbsp;Search</small>
              </button>
            </div> */}

      <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-center gap-3">
        <div className="col-12 col-md-8 col-lg-6">
          <div className="d-flex align-items-center justify-content-evenly gap-2">
            <input
              className="form-control box-shadow-0 border-0 rounded"
              type="text"
              style={{ background: "rgb(239, 239, 239)", height: "45px", maxWidth: "300px" }}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  reload();
                }
              }}
              value={searchTerm}
              placeholder={`Search ${role}`}
              aria-label={`Search ${role} Name Here...`}
              aria-describedby="button-addon2"
            />
            <button
              className="btn btn-success fw-thin text-white border-0 rounded"
              type="button"
              id="button-addon2"
              style={{ background: "#9b5ffd", height: "25px", minWidth: "100px" }}
              onClick={() => {
                reload();
              }}
            >
              <i className="bi bi-search"></i>
              <small className="d-none d-lg-inline-block">&nbsp;Search</small>
            </button>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center mt-3 flex-wrap mt-md-0">
          <button
            style={{ background: "white", height: "25px" }}
            type="button"
            className="btn border-success text-success btn-outline-dark rounded-3"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            <FilePresentTwoTone />
            Upload&nbsp;CSV
          </button>
        </div>
      </div>


      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="exampleModalLong"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content" style={{ minWidth: "800px" }}>
            <div className="modal-header">
              <h5
                className="modal-title text-capitalize"
                id="exampleModalLongTitle"
              >
                Register School Safety {role}
              </h5>
              <button
                ref={closeBtn}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body  p-0">
              <StudentSingleRegister
                role={role}
                closeBtn={closeBtn}
                collegeId={details.id}
                reload={reload}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Bulk Upload Modal  */}

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" style={{ maxWidth: '50%' }}>
          <div className="modal-content rounded-4">
            <div className="modal-header border-0">
              <h1
                className="modal-title font-weight-bold fs-5"
                id="exampleModalLabel"
              >
                {role} Bulk Register
              </h1>
              <button
                type="button"
                onClick={handleClose}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="container-fluid p-0 p-lg-2">
                {!dataSaved && (
                  <div>
                    {!fileData ? (
                      <div>
                        <div className="text-center item-center text-danger">
                          <span className="font-weight-bold">NOTE</span>
                          <br />
                          <span className="lh-1 fw-semibold">
                            1. Only the Provided Format is allowed for file upload <br />
                            2. Data limit is 2000 Users per Upload.{" "}
                          </span>{" "}
                          <br />
                          <Tooltip title="Use Provided file format to upload the student details">
                            <Button
                              style={{ color: "white" }}
                              className="mt-2 rounded"
                              variant="contained"
                              color="success"
                              href="https://s3.ap-south-1.amazonaws.com/dev-yuvamanthan.org/data/uploadInstituteLogo//Bulkteachersformat.xlsx"
                              target="_blank"
                              startIcon={<Download />}
                            >
                              Download&nbsp;Template
                            </Button>
                          </Tooltip>
                        </div>
                        <div
                          className="rounded-4 pb-3 mt-4 mx-auto"
                          style={{
                            position: "relative",
                            overflow: "hidden",
                            maxWidth: "500px",
                            border: "2px dashed grey",
                          }}
                        >
                          <input
                            className="fade"
                            style={{
                              minHeight: 240,
                              width: "100%",
                              zIndex: 200,
                              position: "relative",
                            }}
                            type="file"
                            onClick={(e) => {
                              setFileData();
                              setFile();
                              e.target.value = null;
                            }}
                            onChange={(e) => {
                              setIsLoading(true);
                              setFile(e.target.files[0]);
                              convertFile(e);
                              setIsLoading(false);
                            }}
                            id="fileData"
                          />

                          {isLoading && <YuvaLoader />}
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              zIndex: 100,
                            }}
                            className="w-100 h-100"
                          >
                            <div className="h-100 w-100 bg-light">
                              <div className="py-4 text-center">
                                <h4>Drag and drop or Click To Add File.</h4>
                                <img
                                  src="/images/upload-cloud-folder.png"
                                  alt=""
                                  width={200}
                                  style={{ objectFit: "contain" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <BulkDataFormat
                        // getExcelSheetData={getExcelSheetData}
                        data={fileData}
                        onClose={handleClose}
                        setFileData={setFileData}
                        // setFile={setFile}
                        upload={setStudentData}
                      />
                    )}
                  </div>
                )}
              </div>
              <div
                hidden={!dataSaved || !dataSaved}
                className="container text-center item-center m-2 p-2"
              >
                <p className=" text-decoration-underline lh-1">
                  {" "}
                  Download the Data in Excel File{" "}
                </p>
                <p
                  className="text-decoration-underline lh-1"
                  style={{ color: "red", fontSize: "15px" }}
                >
                  {" "}
                  (*Only Available once.){" "}
                </p>
                <Button
                  hidden={!dataSaved?.length}
                  className="rounded border mx-2"
                  color="success"
                  onClick={downloadSavedData}
                >
                  Registered Data
                </Button>
                <Button
                  hidden={!dataFailed?.length}
                  className="rounded border mx-2"
                  onClick={() => {
                    downloadExcel(dataFailed);
                  }}
                >
                  Unregistered Data
                </Button>
              </div>

              {dataSaved && !!Object.keys(dataSaved).length && (
                <div className="container mx-auto my-4 align-items-center text-center">
                  <h5 className="font-weight-bold text-center">
                    Student Registered {Object.keys(dataSaved).length}{" "}
                  </h5>
                  <table className="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th>Is Present</th>
                        <th>Student ID</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Contact</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataSaved &&
                        dataSaved.map(
                          ({ id, first_name, last_name, contact, email }) => {
                            return (
                              <tr key={id}>
                                <td>Yes/ No</td>
                                <td>{id}</td>
                                <td>{first_name}</td>
                                <td>{last_name} </td>
                                <td>{contact}</td>
                                <td>{email}</td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
              )}
              {dataFailed && !!Object.keys(dataFailed).length && (
                <div className="container mx-auto my-4 aline-cengter text-center">
                  <h5 className="font-weight-bold text-center">
                    Student Failed To Register
                  </h5>
                  <table className="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Contact</th>
                        <th>Email</th>
                        <th>Reason</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataFailed &&
                        dataFailed.map(
                          ({
                            First_name,
                            Last_name,
                            Contact,
                            Email,
                            Error,
                          }) => {
                            return (
                              <tr key={Email}>
                                <td>{First_name}</td>
                                <td>{Last_name} </td>
                                <td>{Contact}</td>
                                <td>{Email}</td>
                                <td className="text-danger font-weight-bold">
                                  {Error}
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

    </>
  );
};